import { Component, OnInit, Input } from '@angular/core';
import * as _ from 'lodash';
import { Contact } from 'src/app/_models/contact';

@Component({
    selector: 'contact-display',
    templateUrl: './contact-display.component.html',
    styleUrls: ['./contact-display.scss']
})
export class ContactDisplayComponent implements OnInit {
    @Input() contact: Contact;

    constructor() {
        
    }

    ngOnInit() {

    }
}
