<main class="p2-app">
    <section class="profile">
        <div class="content">
            <h2>
                <div>Your Upcoming Events</div> 
            </h2>

            <div class="flex-column">
                <event-calendar [onlyShowUserEvents]="true"></event-calendar>
            </div>
        </div>
    </section>
</main>
