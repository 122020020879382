import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map, of, take, tap } from 'rxjs';
import { Contact } from 'src/app/_models/contact';
import * as _ from 'lodash';
import { JWTTokenService } from './jwt-token.service';
import { ContactService } from './contact.service';

@Injectable()
export class UserService {
    user = new BehaviorSubject<Contact>(null);
    userChange = this.user.asObservable();

    constructor(private contactService: ContactService, private jwtTokenService: JWTTokenService) { }

    init() {
        this.refreshUser();
    }

    getUser(): Observable<Contact> {
        return this.userChange;
    }

    setUser(contact: Contact) {
        this.user.next(contact);
    }

    refreshUser() {
        var contactGuid = this.jwtTokenService.getContactGuid();

        if (!!contactGuid) {
            this.contactService.getContact(contactGuid).pipe(take(1)).subscribe(res => {
                this.setUser(res);
            });
        }
    }
}
