<main class="p2-app">
    <section class="events">
        <div class="content">
            <h4 class="text-with-icon" *ngIf="isUnsubscribed">
                <i class="fa fa-check-circle success"></i>
                <span class="unsubscribed">You have been unsubscribed from email communication.</span>
            </h4>
        </div>
    </section>
</main>
