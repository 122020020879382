<form #myForm="ngForm">
    <div class="field" *ngIf="!requestedSmsCode">
        <div class="flex-column tighter">
            <label for="phoneNumber" class="font-semibold">Phone number</label>

            <div class="flex-row tight">
                <input type="tel" id="phoneNumber" name="phoneNumber" #phoneNumber="ngModel"
                [(ngModel)]="phone" usPhoneValidator placeholder="(999) 999-9999" required />
                
                <button class="pink" (click)="submitPhoneNumber()" [disabled]="myForm.form.invalid" [loading]="isLoading">Send Code</button>
            </div>

            <sms-consent></sms-consent>
                        
            <div *ngIf="phoneNumber.invalid && (phoneNumber.dirty || phoneNumber.touched)">
                <small class="error" *ngIf="phoneNumber.errors?.['usPhoneValidator']">Invalid phone number</small>
            </div>
            <div *ngIf="!!phoneApiErrorMsg">
                <small class="error">{{ phoneApiErrorMsg }}</small>
            </div>
        </div>
    </div>
    <div class="field flex-column tighter" *ngIf="requestedSmsCode">
        <h5 class="text-with-icon"><i class="fa fa-comment"></i>Text sent to {{ phone | phone }}</h5>
        <br>

        <label>Enter the verification code</label>

        <div class="flex-row tight">
            <input type="text" id="otp" name="otp" autocomplete="disabled" #otp="ngModel"
            [(ngModel)]="verificationCode" minlength="6" maxlength="6" required />

            <button class="pink" (click)="verifyCode()" [loading]="isLoading">Verify</button>
        </div>

        <div *ngIf="!!codeApiErrorMsg">
            <small class="error">{{ codeApiErrorMsg }}</small>
        </div>
        <br>
        <div class="flex-row tight">
            <a (click)="submitPhoneNumber()" [attr.disabled]="isLoading">Resend code</a> 
            <a (click)="onChangePhoneNumber()">Change phone number</a>
        </div>
    </div>
</form>

