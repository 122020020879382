import { Injectable } from '@angular/core';
import { jwtDecode } from 'jwt-decode'

@Injectable()
export class JWTTokenService {

    jwtToken: string;
    decodedToken: { [key: string]: string };

    constructor() {
    }

    isAuthenticated() {
      return !!this.getToken() && !this.isTokenExpired();
    }

    getToken() {
      return localStorage.getItem('p2-token');
    }

    decodeToken() {
      let token = this.getToken();

      if (!!token) {
        this.decodedToken = jwtDecode(token);
      }
      else {
        this.decodedToken = null;
      }
    }

    getExpiryTime() {
      this.decodeToken();
      return this.decodedToken ? this.decodedToken['exp'] : null;
    }

    getContactGuid() {
      this.decodeToken();
      return this.decodedToken ? this.decodedToken['userdata'] : null;
    }
    
    getPhoneNumber() {
      this.decodeToken();
      return this.decodedToken ? this.decodedToken['mobilephone'] : null;
    }

    isTokenExpired(): boolean {
      const expiryTime: number = parseFloat(this.getExpiryTime());
      if (expiryTime) {
        return ((1000 * expiryTime) - (new Date()).getTime()) < 5000;
      } 
      else {
        return false;
      }
    }
}