<div class="p2-dialog">
    <header>
        <h1>Register for Event</h1>
        <button type="button" role="button" aria-label="Close" class="close" (click)="onCancel()">
            <i class="fa fa-xmark"></i>
        </button>
    </header>
    <div class="content">
        <event-display [event]="event"></event-display>
        <div class="flex-column tight" *ngIf="!!contactEvent.contact?.guid">
            <contact-display [contact]="contactEvent.contact"></contact-display>
    
            <div class="flex-row tight">
                <a (click)="onChangeContact()" [attr.disabled]="isLoading">Logout</a> 
                <a (click)="onEditContact()" *ngIf="currentStep != 'contact-basic-info'">Edit Details</a>
            </div>
        </div>
        <phone-lookup *ngIf="currentStep == 'contact-selection'" [(contact)]="contactEvent.contact" (contactChange)="onNextClick()"></phone-lookup>

        <div class="flex-column tight" *ngIf="currentStep == 'contact-basic-info'">
            <h5>Parent Info</h5>
            <contact-basic-info-form [(contact)]="contactEvent.contact" [(isFormValid)]="isFormValid"></contact-basic-info-form>
            <sms-consent *ngIf="isNewContact()"></sms-consent>
        </div>

        <child-selection *ngIf="currentStep == 'child-selection'" [enableSelection]="true" [parentGuid]="contactEvent.contact.guid" [(children)]="contactEvent.contact.children" title="Which children are attending?"></child-selection>
    </div>
    <footer>
        <button class="secondary" (click)="onCancel()">Cancel</button>
        <button class="light-blue with-icon right"  (click)="onNextClick()" [loading]="isLoading" [disabled]="!isFormValid || !contactEvent?.contact">
            {{currentStep == 'child-selection' ? 'Finish' : 'Next' }}
            <i class="fa fa-chevron-right" *ngIf="currentStep != 'child-selection'"></i>
        </button>
    </footer>
</div>
