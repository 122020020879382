import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import { ContactEvent } from 'src/app/_models/contact-event';
import * as _ from 'lodash';

export interface AlertDialogData {
    title: string;
    message: string;
    buttonText: string;
}

@Component({
    templateUrl: './alert-dialog.component.html',
    styleUrls: ['./alert-dialog.component.scss']
})
export class AlertDialogComponent implements OnInit {
    title: string;
    message: string;
    buttonText: string = 'OK';

    constructor(public dialogRef: DialogRef<ContactEvent>, @Inject(DIALOG_DATA) public data: AlertDialogData) { }

    ngOnInit(): void {
        this.title = this.data.title;
        this.message = this.data.message;
        this.buttonText = this.data.buttonText;
    }

    onCancel() {
        this.dialogRef.close();
    }
}
