import { Injectable } from "@angular/core";
// import { ToastrService } from "ngx-toastr";
import { HttpErrorResponse } from "@angular/common/http";
import { AlertDialogComponent } from "../_components/alert-dialog/alert-dialog.component";
import { Dialog } from "@angular/cdk/dialog";
import { ErrorService } from "./error.service";
import * as _ from 'lodash';

@Injectable({
    providedIn: "root",
})
export class HandleErrorService {
    constructor(
        public dialog: Dialog,
        private errorService: ErrorService
    ) { }

    // Handling HTTP Errors using Toaster
    public handleError(err: HttpErrorResponse) {
        let errorMessage: string;
        
        if (!!err?.error?.Message) {
            errorMessage = err.error.Message;
        }
        else if (!!err?.error?.message) {
            errorMessage = err.error.message;
        }
        else if (!!err?.message) {
            errorMessage = err.message
        }

        console.log(errorMessage);
        
        // Ensure we don't open multiple error alert boxes
        if (this.dialog.openDialogs.length == 0 || 
            !_.every(this.dialog.openDialogs, (dialog) => dialog.componentInstance.title == 'Error')) {

            const dialogRef = this.dialog.open<string>(AlertDialogComponent, {
                data: { title: 'Error', message: 'Hmm, something went wrong. Please try again later.', buttonText: 'OK' }
            });
    
            dialogRef.closed.subscribe(result => {
                
            });
        }
    }
}