import { inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  NavigationExtras,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { JWTTokenService } from './jwt-token.service';

@Injectable()
export class PermissionsService {

  constructor(private router: Router, private jwtService: JWTTokenService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.jwtService.isAuthenticated()) {
      return true;
    }
    else {
      this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }} as NavigationExtras);

      return false;
    }
  }
}

export const AuthGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean => {
  return inject(PermissionsService).canActivate(next, state);
}
