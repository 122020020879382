import { Component, OnInit, Input } from '@angular/core';
import * as _ from 'lodash';
import { Contact } from 'src/app/_models/contact';

@Component({
    selector: 'sms-consent',
    templateUrl: './sms-consent.component.html',
    styleUrls: ['./sms-consent.scss']
})
export class SmsConsentComponent implements OnInit {

    constructor() {
        
    }

    ngOnInit() {

    }
}
