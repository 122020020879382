import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Inject } from '@angular/core';

@Component({
	templateUrl: './life-skills-classes.component.html',
	styleUrls: ['./life-skills-classes.component.scss']
})
export class LifeSkillsClassesComponent implements AfterViewInit {

	constructor(
		private elementRef: ElementRef,
		@Inject(DOCUMENT) private document: Document
	) { }

	ngAfterViewInit() {
		const s = this.document.createElement('script');
		s.type = 'text/javascript';
		s.src = '//apps.elfsight.com/p/platform.js';
		this.elementRef.nativeElement.appendChild(s);
	}
}
