import { AfterViewInit, Component, OnInit } from '@angular/core';
import { register } from 'swiper/element/bundle';

@Component({
  templateUrl: './mommy-and-me.component.html',
  styleUrls: ['./mommy-and-me.component.scss']
})
export class MommyAndMeComponent implements OnInit, AfterViewInit {

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    register();
  }
}
