import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import * as _ from 'lodash';
import { take } from 'rxjs';
import { Contact } from 'src/app/_models/contact';
import { ContactService } from 'src/app/_services/contact.service';
import { JWTTokenService } from 'src/app/_services/jwt-token.service';

@Component({
    selector: 'phone-lookup',
    templateUrl: './phone-lookup.component.html',
    styleUrls: ['./phone-lookup.scss']
})
export class PhoneLookupComponent implements OnInit {
    @Input() contact: Contact;
    @Input() isPhoneChange: boolean = false;

    @Output() contactChange = new EventEmitter<Contact>();
    
    isLoading: boolean = false;
    phone: string;
    verificationCode: string;
    requestedSmsCode: boolean = false;
    phoneApiErrorMsg: string;
    codeApiErrorMsg: string;

    constructor(private contactService: ContactService, private jwtTokenService: JWTTokenService) {
    }

    ngOnInit() {

    }

    submitPhoneNumber() {
        this.isLoading = true;
        this.phoneApiErrorMsg = null;
        
        // Submit phone number to receive text
        this.contactService.authenticateWithPhoneNumber(this.phone).pipe(take(1)).subscribe(res => {
            this.requestedSmsCode = true;

            this.isLoading = false;
        }
        ,err => {
            this.phoneApiErrorMsg = err.error?.message ?? err.message;
            this.isLoading = false;
        })
    }

    verifyCode() {
        this.isLoading = true;
        this.codeApiErrorMsg = null;

        if (this.isPhoneChange) {
            this.contactService.changePhoneNumber(this.contact.guid, this.phone, this.verificationCode).pipe(take(1)).subscribe({
                next: res => {
                    this.requestedSmsCode = true;
    
                    localStorage.setItem('p2-token', res.token);
        
                    if (this.jwtTokenService.getContactGuid()?.length > 0) {
                        this.contactService.getContact(this.jwtTokenService.getContactGuid()).pipe(take(1)).subscribe(res => {
                            this.contact = res;
                            this.contactChange.emit(this.contact);
    
                            this.isLoading = false;
                        })
                    }
                },
                error: err => {
                    this.codeApiErrorMsg = err.error.message;
                    this.isLoading = false;
                    this.verificationCode = null;
                }})
    
        }
        else {
            this.contactService.validateCode(this.phone, this.verificationCode).pipe(take(1)).subscribe({
                next: res => {
                    this.requestedSmsCode = true;
    
                    localStorage.setItem('p2-token', res.token);
        
                    // Existing contact
                    if (this.jwtTokenService.getContactGuid()?.length > 0) {
                        this.contactService.getContact(this.jwtTokenService.getContactGuid()).pipe(take(1)).subscribe(res => {
                            this.contact = res;
                            this.contactChange.emit(this.contact);
    
                            this.isLoading = false;
                        })
                    }
                    // New contact
                    else {
                        this.contact = { phoneNumber: this.phone } as Contact;
                        this.contactChange.emit(this.contact);
    
                        this.isLoading = false;
                    }
                },
                error: err => {
                    this.codeApiErrorMsg = err.error.message;
                    this.isLoading = false;
                    this.verificationCode = null;
                }})
        }
    }

    onChangePhoneNumber() {
        this.requestedSmsCode = false;
        this.phone = null;
    }
}
