<main>
    <h2>FAQs</h2>
    
    <ul>
        <li>
            <p class="question">How much do your services cost?</p>
            <p class="answer">Our services are provided free of charge because of generous donors.</p>
        </li>
        <li>
            <p class="question">Is this a program?  How long does a mentoring relationship last?</p>
            <p class="answer">Parenting with Purpose is not a program with a graduation.  There is no set timeline for completion.</p>
        </li>
        <li>
            <p class="question">How will a mentor help me?</p>
            <p class="answer">Your mentor is an experienced mom who wants to listen to your story and learn about your hopes and aspirations.  You will work together to set goals for various aspects of your life.  Meeting together over coffee or lunch (or Zoom), your mentor will provide wisdom to navigate the obstacles facing you in your parenting journey.  If you're feeling upset or scared, you'll have a caring mentor to text or call.  Your mentor will do what it takes to help you become the best parent you can be.</p>
        </li>
    </ul>
</main>

