import { Component, OnInit, Input, EventEmitter, Output, Inject } from '@angular/core';
import * as _ from 'lodash';
import { Contact } from 'src/app/_models/contact';
import * as dayjs from 'dayjs';
import { Dialog } from '@angular/cdk/dialog';
import { EditChildDialogComponent } from '../edit-child-dialog/edit-child-dialog.component';

@Component({
    selector: 'child-selection',
    templateUrl: './child-selection.component.html',
    styleUrls: ['./child-selection.scss']
})
export class ChildSelectionComponent implements OnInit {
    @Input() children: Contact[] = [];
    @Input() title: string;
    @Input() parentGuid: string;
    @Input() enableSelection: boolean = false;

    @Output() childrenChange = new EventEmitter<Contact[]>();

    isLoading: boolean = false;
    
    allStates: any[] = [
        { name: 'Attending', value: true },
        { name: 'Not Attending', value: false }
    ];

    constructor(public dialog: Dialog) {

    }

    ngOnInit() {
    }

    onAddChild() {
        const dialogRef = this.dialog.open<{child: Contact, isDeleted?: boolean}>(EditChildDialogComponent, {
            data: { child: {} as Contact, action: 'add', parentGuid: this.parentGuid }
        });

        dialogRef.closed.subscribe(result => {
            this.onChildSaved(result)
        });

    }

    onEditChild(child: Contact) {
        const dialogRef = this.dialog.open<{child: Contact, isDeleted?: boolean}>(EditChildDialogComponent, {
            data: { child: child, action: 'edit', parentGuid: this.parentGuid }
        });

        dialogRef.closed.subscribe(result => {
            this.onChildSaved(result)
        });
    }

    getAge(child: Contact) {
        return !!child.dateOfBirth ? dayjs().diff(dayjs(child.dateOfBirth), 'year') : 'Unknown';
    }

    onSelectChild(child: Contact) {
        child.isSelected = true;
    }

    onRemoveSelectionChild(child: Contact) {
        child.isSelected = false;
    }

    onChildSaved(res: { child: Contact, isDeleted?: boolean }) {
        if (!!res) {
            let index = _.findIndex(this.children, c => c.guid == res.child.guid);
            let existingChild = this.children.find(c => c.guid == res.child.guid);
    
            if (index > -1) {
                if (res.isDeleted) {
                    this.children.splice(index, 1);
                }
                else {
                    if (existingChild) {
                        res.child.isSelected = existingChild.isSelected;
                    }
        
                    this.children.splice(index, 1, res.child);
                }
    
                this.childrenChange.emit(this.children);
            }
            else {
                this.children.push(res.child);
                this.childrenChange.emit(this.children);
    
                res.child.isSelected = true;
            }
        }
    }
}
