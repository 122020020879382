import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Dialog } from '@angular/cdk/dialog';
import { Ethnicity } from 'src/app/_models/ethnicity';
import { EthnicityService } from 'src/app/_services/ethnicity.service';
import { CheckboxListOption } from '../checkbox-list/checkbox-list.component';

@Component({
    selector: 'contact-ethnicity-form',
    templateUrl: './contact-ethnicity-form.component.html',
    styleUrls: ['./contact-ethnicity-form.scss']
})
export class ContactEthnicityComponent implements OnInit {
    @ViewChild('myForm', { static: true }) myForm: NgForm;

    @Input() selectedEthnicities: Ethnicity[] = [];
    
    @Output() selectedEthnicitiesChange = new EventEmitter<Ethnicity[]>();

    isLoading: boolean = false;
    
    ethnicities: Ethnicity[] = [];

    allOptions: CheckboxListOption[] = [];
    selectedOptions: CheckboxListOption[] = [];

    constructor(public dialog: Dialog, private ethnicityService: EthnicityService) {
        
    }

    ngOnInit() {
        this.isLoading = true;

        this.ethnicityService.getAll().subscribe(res => {
            this.ethnicities = res;

            this.allOptions = _.map(this.ethnicities, e => { return { name: e.name, value: e } as CheckboxListOption});
            this.selectedOptions = _.map(this.selectedEthnicities, e => { return { name: e.name, value: e } as CheckboxListOption});

            this.isLoading = false;
        });
    }

    onSelectedOptionsChange(selectedOptions: CheckboxListOption[]) {
        this.selectedEthnicities = _.map(selectedOptions, o => o.value);
        this.selectedEthnicitiesChange.emit(this.selectedEthnicities);
    }
}
