import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';

@Component({
  templateUrl: './volunteer.component.html',
  styleUrls: ['./volunteer.component.scss']
})
export class VolunteerComponent implements OnInit {
  @ViewChild('volunteer') volunteer;
  @ViewChild('prayer') prayer;

  constructor() { }

  ngOnInit(): void {
    
  }

  ngAfterViewInit() {
		var volunteerScript = document.createElement("script");
		volunteerScript.async = true;
		volunteerScript.setAttribute("src", "https://www.cognitoforms.com/f/seamless.js");
    volunteerScript.setAttribute("data-key", "2bfEHdCOdUeFWW4pLbCtWA");
    volunteerScript.setAttribute("data-form", "4");
    this.volunteer.nativeElement.appendChild(volunteerScript);

		var prayerScript = document.createElement("script");
		prayerScript.async = true;
		prayerScript.setAttribute("src", "https://www.cognitoforms.com/f/seamless.js");
    prayerScript.setAttribute("data-key", "2bfEHdCOdUeFWW4pLbCtWA");
    prayerScript.setAttribute("data-form", "5");
    this.prayer.nativeElement.appendChild(prayerScript);
  }

}
