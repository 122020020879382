import { Component, OnInit, Input, EventEmitter, Output, Inject, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as _ from 'lodash';

export interface SelectButtonOption {
    name: string;
    value: any;
}

@Component({
    selector: 'select-button',
    templateUrl: './select-button.component.html',
    styleUrls: ['./select-button.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SelectButtonComponent),
            multi: true,
        }
    ]
})
export class SelectButtonComponent implements ControlValueAccessor {
    @Input() options: SelectButtonOption[] = [];
    @Input() ngModel: any;

    @Output() ngModelChange = new EventEmitter<any>();

    private onChange: (value: any) => void;
    private onTouched: () => void;

    constructor() {

    }

    onClickOption(option: SelectButtonOption) {
        this.ngModel = option.value;
        this.ngModelChange.emit(this.ngModel);
        this.onChange(this.ngModel);
    }

    public writeValue(value: any): void {
        this.ngModel = value;
    }

    public registerOnChange(fn: (value: any) => void): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    public setDisabledState?(isDisabled: boolean): void {
        // Implement if needed
    }

    public onBlur(): void {
        this.onTouched();
    }
}
