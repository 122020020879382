import { Component, OnInit, Input, EventEmitter, Output, Inject } from '@angular/core';
import * as _ from 'lodash';
import { Contact } from 'src/app/_models/contact';
import * as dayjs from 'dayjs';
import { take } from 'rxjs';
import { DialogRef, DIALOG_DATA, Dialog } from '@angular/cdk/dialog';
import { ContactEvent } from 'src/app/_models/contact-event';
import { DialogData } from '../event-calendar/event-calendar.component';
import { ContactService } from 'src/app/_services/contact.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';

export interface EditChildDialogData {
    child: Contact;
    action: string;
    parentGuid: string;
}

@Component({
    selector: 'edit-child-dialog',
    templateUrl: './edit-child-dialog.component.html',
    styleUrls: ['./edit-child-dialog.scss']
})
export class EditChildDialogComponent implements OnInit {
    child: Contact;
    action: string;
    parentGuid: string;

    isLoading: boolean = false;
    isFormValid: boolean = false;

    allGenders: any[] = [
        { name: 'Unspecified', value: undefined },
        { name: 'Female', value: 'F' },
        { name: 'Male', value: 'M' }
    ];
    
    constructor(public dialogRef: DialogRef<{child: Contact, isDeleted?: boolean}>, @Inject(DIALOG_DATA) public data: EditChildDialogData, private contactService: ContactService, public dialog: Dialog) {

    }

    ngOnInit() {
        this.child = _.cloneDeep(this.data.child);
        this.action = this.data.action;
        this.parentGuid = this.data.parentGuid;
    }

    onRemoveChild() {
        const dialogRef = this.dialog.open<boolean>(ConfirmationDialogComponent, {
            data: { 
                title: 'Remove child?', 
                message: `Are you sure you want to completely remove ${this.child.firstName} ${this.child.lastName} from the system?`,
                yesButton: 'Remove child',
                noButton: 'Nevermind'
            }
        });

        dialogRef.closed.subscribe(result => {
            if (result == true) {
                this.contactService.removeChildFromParent(this.parentGuid, this.child.guid).pipe(take(1)).subscribe({
                    next: res => {
                        this.isLoading = false;
        
                        this.dialogRef.close({ child: this.child, isDeleted: true});
                    },
                    error: err => {
                        this.isLoading = false;
                    }})
            }
        });

    }

    onSave() {
        if (this.child) {
            this.isLoading = true;
            
            if (this.action == 'add') {
                this.contactService.addChild(this.parentGuid, this.child).pipe(take(1)).subscribe({
                    next: res => {
                        this.child = res;

                        this.isLoading = false;

                        this.dialogRef.close({ child: this.child});
                    },
                    error: err => {
                        this.isLoading = false;
                    }})
            }
            else if (this.action == 'edit') {
                if (this.child.guid?.length > 0) {
                    this.contactService.updateChild(this.parentGuid, this.child).pipe(take(1)).subscribe({
                        next: res  => {
                            this.child = res;

                            this.isLoading = false;

                            this.dialogRef.close({ child: this.child});
                        },
                        error: err => {
                            this.isLoading = false;
                        }})
                }
            }
        }
    }

    onCancel() {
        this.dialogRef.close();
    }
}
