import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { Subscription, take } from 'rxjs';
import { Contact } from 'src/app/_models/contact';
import { ContactService } from 'src/app/_services/contact.service';
import { UserService } from 'src/app/_services/user.service';

@Component({
    templateUrl: './login.component.html',
    styleUrls: ['./login.scss']
})
export class LoginComponent implements OnInit {
    contact: Contact;
    returnUrl: string;

    constructor(private contactService: ContactService, private userService: UserService, private router: Router, private route: ActivatedRoute) {
    }

    ngOnInit() {
        this.route.queryParamMap.subscribe(params => {
            if (!!params.get('returnUrl')) {
                this.returnUrl = params.get('returnUrl');
            }
        });
    }

    onCancel() {

    }

    onContactChanged(contact: Contact) {
        if (!!contact?.guid) {
            this.userService.setUser(contact);

            if (this.returnUrl?.length > 0) {
                this.router.navigateByUrl(this.returnUrl);    
            }
            else {
                if (this.contactService.isBasicInfoComplete(contact)) {
                    this.router.navigate(['/user/events']);
                }
                else {
                    // The user has logged in with a phone number to an imported account, but has not completed their profile
                    this.router.navigate(['/user/profile']);
                }
            }
        }
        // This is the case where the user logged in with a phone number but has not registered yet
        else if (!!contact?.phoneNumber) {
            this.router.navigate(['/register']);
        }
    }
}
