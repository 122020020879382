import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { MissionComponent } from './mission/mission.component';
import { StaffBoardComponent } from './staff-board/staff-board.component';
import { DonateComponent } from './donate/donate.component';
import { EventsComponent } from './events/events.component';
import { FaqsComponent } from './faqs/faqs.component';
import { PartnersComponent } from './partners/partners.component';
import { RegisterComponent } from './register/register.component';
import { CurveTopComponent } from './_components/curve-top/curve-top.component';
import { CurveBottomComponent } from './_components/curve-bottom/curve-bottom.component';
import { AboutComponent } from './about/about.component';
import { Angulartics2Module } from 'angulartics2';
import { LifeSkillsClassesComponent } from './life-skills-classes/life-skills-classes.component';
import { MentoringComponent } from './mentoring/mentoring.component';
import { JobsComponent } from './jobs/jobs.component';
import { HttpClientModule } from '@angular/common/http';
import { MommyAndMeComponent } from './mommy-and-me/mommy-and-me.component';
import { ChampionSponsorComponent } from './champion-sponsor/champion-sponsor.component';
import { HeartBehindP2Component } from './heart-behind-p2/heart-behind-p2.component';
import { EventCalendarComponent } from './_components/event-calendar/event-calendar.component';
import { EventService } from './_services/event.service';
import {DialogModule} from '@angular/cdk/dialog';
import { EventDisplayComponent } from './_components/event-display/event-display.component';
import { EventRegistrationComponent } from './_components/event-registration/event-registration.component';
import { ChildSelectionComponent } from './_components/child-selection/child-selection.component';
import { ContactDisplayComponent } from './_components/contact-display/contact-display.component';
import { ContactBasicInfoFormComponent } from './_components/contact-basic-info-form/contact-basic-info-form.component';
import { FormsModule } from '@angular/forms';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { PhoneLookupComponent } from './_components/phone-lookup/phone-lookup.component';
import { ContactService } from './_services/contact.service';
import { JWTTokenService } from './_services/jwt-token.service';
import { UsPhoneValidator } from './_validators/us-phone-validator';
import { PhonePipe } from './_pipes/us-phone.pipe';
import { httpInterceptorProviders, HttpRequestInterceptor } from './_services/http-request-interceptor.service';
import { HandleErrorService } from './_services/handle-error.service';
import { SelectButtonComponent } from './_components/select-button/select-button.component';
import { ChildDisplayComponent } from './_components/child-display/child-display.component';
import { AlertDialogComponent } from './_components/alert-dialog/alert-dialog.component';
import { UserProfileMenuComponent } from './_components/user-profile-menu/user-profile-menu.component';
import { UserService } from './_services/user.service';
import { UserProfileComponent } from './_components/user-profile/user-profile.component';
import { ChildrenComponent } from './_components/children/children.component';
import { UserEventsComponent } from './_components/user-events/user-events.component';
import { ConfirmationDialogComponent } from './_components/confirmation-dialog/confirmation-dialog.component';
import { LoginComponent } from './_components/login/login.component';
import { EditChildDialogComponent } from './_components/edit-child-dialog/edit-child-dialog.component';
import { LoadingDirective } from './_components/loading/loading.directive';
import { ChangePhoneDialogComponent } from './_components/change-phone-dialog/change-phone-dialog.component';
import { ClientProfileFormComponent } from './_components/client-profile-form/client-profile-form.component';
import { CheckboxListComponent } from './_components/checkbox-list/checkbox-list.component';
import { ContactEthnicityComponent } from './_components/contact-ethnicity-form/contact-ethnicity-form.component';
import { EthnicityService } from './_services/ethnicity.service';
import { PhotoReleaseFormComponent } from './_components/photo-release-form/photo-release-form.component';
import { CheckinComponent } from './checkin/checkin.component';
import { SmsConsentComponent } from './_components/sms-consent/sms-consent.component';
import { UserPreferencesComponent } from './_components/user-preferences/user-preferences.component';
import { UnsubscribeComponent } from './unsubscribe/unsubscribe.component';
import { PermissionsService } from './_services/auth-guard';
import { ErrorService } from './_services/error.service';
import { AngularErrorHandlerService } from './_services/angular-error.service';

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        MissionComponent,
        StaffBoardComponent,
        DonateComponent,
        EventsComponent,
        FaqsComponent,
        PartnersComponent,
        RegisterComponent,
        CurveTopComponent,
        CurveBottomComponent,
        AboutComponent,
        LifeSkillsClassesComponent,
        MentoringComponent,
        MommyAndMeComponent,
        ChampionSponsorComponent,
        HeartBehindP2Component,
        EventCalendarComponent,
        EventRegistrationComponent,
        EventDisplayComponent,
        ChildSelectionComponent,
        ContactDisplayComponent,
        ContactBasicInfoFormComponent,
        PhoneLookupComponent,
        UsPhoneValidator,
        PhonePipe,
        SelectButtonComponent,
        ChildDisplayComponent,
        AlertDialogComponent,
        UserProfileMenuComponent,
        UserProfileComponent,
        ChildrenComponent,
        UserEventsComponent,
        ConfirmationDialogComponent,
        LoginComponent,
        EditChildDialogComponent,
        LoadingDirective,
        ChangePhoneDialogComponent,
        LoginComponent,
        ClientProfileFormComponent,
        CheckboxListComponent,
        ContactEthnicityComponent,
        PhotoReleaseFormComponent,
        CheckinComponent,
        SmsConsentComponent,
        UserPreferencesComponent,
        UnsubscribeComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,
        DialogModule,
        FormsModule,
        CommonModule,
        Angulartics2Module.forRoot(),
        NgOptimizedImage
    ],
    providers: [
        EventService,
        ContactService,
        JWTTokenService,
        HandleErrorService,
        UserService,
        httpInterceptorProviders,
        PhonePipe,
        EthnicityService,
        PermissionsService,
        ErrorService,
        {provide: ErrorHandler, useClass: AngularErrorHandlerService},
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
