<main>
    <h2>Partners</h2>

    <section>
        <h3>Churches</h3>
        <ul>
            <li><a href="//secc.org" target="_blank">Southeast Christian Church</a></li>
        </ul>
    </section>

    <section>
        <h3>Related Ministries</h3>
        <ul>
            <li><a href="//choiceslrccares.org" target="_blank">Choices Life Resource Center</a></li>
            <li><a href="//hopesi.org" target="_blank">Hope Southern Indiana</a></li>
        </ul>
    </section>
</main>
