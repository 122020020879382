<form #myForm="ngForm">
    <h5>Digital Photo Release</h5>
    <div class="flex-column tight">
        <label for="photoRelease" *ngIf="originalPhotoReleaseResponse != 'Accepted'">I hereby grant permission to Parenting with Purpose, Inc. to use photographs and/or
            video of myself and/or my children in publications, advertising, news releases, social media, and in other
            communications related to the mission of Parenting with Purpose, Inc. Furthermore, I understand that no
            royalty, fee, or compensation shall be payable to me by reason of such use.</label>

        <div class="field">
            <select-button id="response" name="response" [options]="allOptions" *ngIf="originalPhotoReleaseResponse != 'Accepted'"
                 [(ngModel)]="photoReleaseResponse" (ngModelChange)="onChange($event)"></select-button>

            <!-- Checkmark with Accepted label -->
            <div *ngIf="originalPhotoReleaseResponse == 'Accepted'" class="flex-row tight">
                <div class="text-with-icon">
                    <i class="fa fa-check-circle success"></i>
                    <label class="accepted">Accepted</label>
                </div>
                <a (click)="onUnlockChange()">Change</a>
            </div>
        </div>

        <label *ngIf="photoReleaseResponse == 'Declined'">
            <b>Please note:</b> If you decline to give permission, you and your children must put on the provided stickers before every event so we can identify those who have declined during photo/video editing.
        </label>
    </div>
</form>
