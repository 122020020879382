import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HTTP_INTERCEPTORS, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable, catchError, tap } from 'rxjs';
import { HandleErrorService } from './handle-error.service';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
  constructor(
    private error: HandleErrorService,
  ) {}
  
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = localStorage.getItem('p2-token');
    if (!!token) {
      req = req.clone({
        url:  req.url,
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
    }
    
    return next.handle(req).pipe(
      tap({
        next: () => null,
        error: (error: HttpErrorResponse) => {
          console.log('Error', error);
          this.error.handleError(error);
        },
      })
    );
  }
}

export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
];