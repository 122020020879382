<main>
    <section class="about">
        <div class="content">
            <div class="container">
                <div class="left">
                    <h3>Vision</h3>
                    <p>A world where young moms are equipped with holistic support and embraced in healthy community to provide their child a stable & nurturing home.</p>
                    <br>
                    <h3>Mission</h3>
                    <p>To equip young moms to make healthy decisions about parenting, relationships, and their future by providing one-on-one mentoring, life skills teaching, and supportive community.</p>
                    <br>
                    <h3>About</h3>
                    <p>As a Christ-centered organization, our faith compels us to love and serve young moms, dads, and their children in our community.  We will never look into the eyes of someone God does not love.</p>
                    <p>We live by the P2 motto:</p>
                    <ul>
                        <li><b>Be Seen</b>: We want you to know that we see you, we know this isn't easy, and we love and respect you for boldly choosing to work toward being the best parent you can be.</li>
                        <li><b>Be Heard</b>: We want to listen to you.  Your story matters.</li>
                        <li><b>Be Known</b>: We want to know you.  You aren't too "messy" for us.  P2 mentors are not here to judge and lecture you. Many of them have been in your shoes.  They want to be the people they themselves needed at that time.  You have <b>purpose</b> and you are loved no matter what has happened in your past.</li>
                    </ul>
                </div>
                <div class="right">
                    <img src="../../assets/p2-baby.png" />
                </div>
            </div>
        </div>
    </section>
    
    <section class="board">
        <div class="content">
            <curve-top></curve-top>
            <div class="container">
                <h3>Board of Directors</h3>
                <ul>
                    <li><label>President: </label><span>Brad Striegel (Small Business Owner, Council Member)</span></li>
                    <li><label>Vice President: </label><span>Bryson Koch (Campus Pastor at Southeast Christian Indiana Campus)</span></li>
                    <li><label>Secretary: </label><span>Christi Peak (Women's Minister at Southeast Christian Indiana Campus)</span></li>
                    <li><label>Treasurer: </label><span>Katie France (Farm Credit Mid-America)</span></li>
                    <li><span>Mark Downs (Software Developer, Co-founder of P2)</span></li>
                </ul>
            </div>
            <curve-bottom></curve-bottom>
        </div>
    </section>

    <section>
        <div class="content">
            <div class="container">
                <h3>Partners</h3>

                <p>Thank you to the organizations who have partnered with us as we serve the community together.</p>

                <ul>
                    <li>Southeast Christian Church</li>
                    <li>New Albany-Floyd County Schools</li>
                    <li>Greater Clark Schools</li>
                    <li>The Excel Center (Clarksville)</li>
                    <li>Floyd County Health Department</li>
                    <li>Choices Life Resource Center</li>
                    <li>Hope Southern Indiana</li>
                    <li>Nurse Family Partnership</li>
                    <li>MESA, A Collaborative Kitchen</li>
                    <li>CASI</li>
                    <li>Floyd County Head Start</li>
                    <li>Jeffersonville Housing Authority</li>
                </ul>
            </div>
        </div>
    </section>

    <section class="supporting">
        <div class="content">
            <curve-top></curve-top>
            <div class="container">
                <h3>Supporting Organizations</h3>

                <p>Thank you to the organizations who financially support the mission of Parenting with Purpose.</p>

                <ul>
                    <li>Southeast Christian Church</li>
                    <li>Graceland Baptist Church</li>
                    <li>Southeast Indiana Missions Committee</li>
                    <li>Ceasars Foundation of Southern Indiana</li>
                    <li>Floyd County Legacy Foundation</li>
                    <li>Community Foundation of Southern Indiana</li>
                    <li>Kiwanis Club of Historic New Albany</li>
                    <li>Ernstberger Orthodontics</li>
                    <li>Samtec</li>
                </ul>

                <div class="supporter-logos">
                    <img src="../../assets/CaesarsFoundation_Logo.png" />
                    <img src="../../assets/Floyd-County-Legacy-foundation.png" />
                </div>
            </div>
            <curve-bottom></curve-bottom>
        </div>
    </section>
</main>
