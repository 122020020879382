import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import * as _ from 'lodash';

@Component({
    templateUrl: './user-events.component.html',
    styleUrls: ['./user-events.scss']
})
export class UserEventsComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {

    }
}
