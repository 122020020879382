import { Component, OnInit, Input } from '@angular/core';
import * as _ from 'lodash';
import { Event } from 'src/app/_models/event';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'event-display',
    templateUrl: './event-display.component.html',
    styleUrls: ['./event-display.scss']
})
export class EventDisplayComponent implements OnInit {
    @Input() event: Event;

    api: string = environment.api;

    constructor() {
        
    }

    ngOnInit() {

    }

    getImageUrl(event: Event, imageType: string) : string {
        return `${this.api}events/${event.guid}/image/${imageType}`;
    }
}
