<div class="flex-column tight">
    <h5 class="w-full" *ngIf="!!title">{{ title }}</h5>

    <table class="p2-list">
        <tbody>
            <ng-container *ngFor="let child of children" >
                <tr>
                    <td>
                        <div class="card flex-column tight col-12 mb-0" [class.selected]="child.isSelected && enableSelection">
                            <div class="flex-row tighter row-one">
                                <child-display [contact]="child"></child-display>
                                <div class="flex-row tight">
                                    <button class="round" (click)="onEditChild(child)"><i class="fas fa-pencil"></i></button>
                                </div>
                            </div>
                            
                            <select-button *ngIf="enableSelection"
                                [options]="allStates" 
                                [(ngModel)]="child.isSelected" />
                        </div>
                    </td>
                </tr>
            </ng-container>
        </tbody>
    </table>

    <button class="pink with-icon" (click)="onAddChild()"><i class="fa fa-plus"></i>Add child</button>
</div>
