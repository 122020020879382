import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'curve-top',
  templateUrl: './curve-top.component.html',
  styleUrls: ['./curve-top.component.scss']
})
export class CurveTopComponent implements OnInit {
  @Input() bgColor: string = '#F1F7FA';
  
  constructor() { }

  ngOnInit(): void {
  }

}
