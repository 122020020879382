import {ErrorHandler, Injectable} from '@angular/core';
import { ErrorService } from './error.service';
import { take } from 'rxjs';
import * as _ from 'lodash';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

@Injectable()
export class AngularErrorHandlerService extends ErrorHandler {

    constructor(private errorService: ErrorService, private route: ActivatedRoute, private location: Location) {
        super();
    }

    handleError(error: Error) {
        this.errorService.reportError({ name: error.name, message: error.message, url: window.location.href, stack: error.stack }).pipe(take(1)).subscribe({
            next: () => {
                console.log('Error reported');
            },
            error: (err) => {
                console.error('Error reporting error', err);
            }
        });

        super.handleError(error);
    }
}