import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Dialog } from '@angular/cdk/dialog';
import { ClientInfo } from 'src/app/_models/client-info';

@Component({
    selector: 'client-profile-form',
    templateUrl: './client-profile-form.component.html',
    styleUrls: ['./client-profile-form.scss']
})
export class ClientProfileFormComponent implements OnInit {
    @ViewChild('myForm', { static: true }) myForm: NgForm;

    @Input() clientInfo: ClientInfo = { preferredLanguage: 'English' } as ClientInfo;
    @Input() isFormValid: boolean = false;
    
    @Output() clientInfoChange = new EventEmitter<ClientInfo>();
    @Output() isFormValidChange = new EventEmitter<boolean>();

    isLoading: boolean = false;
    subscription: Subscription;
    
    allLanguages: any[] = [
        { name: 'English', value: 'English' },
        { name: 'Español', value: 'Español' }
    ];

    constructor(public dialog: Dialog) {
    }

    ngOnInit() {
        this.subscription = this.myForm.statusChanges
        .subscribe(() => {
            this.isFormValid = !this.myForm.form.invalid;
            this.isFormValidChange.emit(this.isFormValid);
        })
    }

    ngOnDestroy() {
        if (!!this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
