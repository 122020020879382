import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import * as _ from 'lodash';
import { forkJoin, Subscription, take } from 'rxjs';
import { ClientInfo } from 'src/app/_models/client-info';
import { Contact } from 'src/app/_models/contact';
import { ContactService } from 'src/app/_services/contact.service';
import { JWTTokenService } from 'src/app/_services/jwt-token.service';
import { UserService } from 'src/app/_services/user.service';

@Component({
    templateUrl: './user-profile.component.html',
    styleUrls: ['./user-profile.scss']
})
export class UserProfileComponent implements OnInit {
    contact: Contact;
    
    userSubscription: Subscription;
    isLoading: boolean = false;
    isProfileFormValid: boolean = true;
    isClientFormValid: boolean = true;

    wasSaved: boolean = false;

    constructor(private contactService: ContactService, private jwtTokenService: JWTTokenService, private userService: UserService) {
        
    }

    ngOnInit() {
        this.userSubscription = this.userService.getUser().subscribe(user => {
            if (!!user) {
                this.contact = user;
                this.contact.clientInfo = this.contact?.clientInfo ?? { preferredLanguage: 'English' } as ClientInfo;
            }
        })
    }

    ngOnDestroy() {
        if (!!this.userSubscription) {
            this.userSubscription.unsubscribe();
        }
    }

    onCancel() {

    }

    onSave() {
        this.isLoading = true;

        forkJoin([
            this.contactService.updateContact(this.contact),
            this.contactService.updateContactEthnicities(this.contact.guid, _.map(this.contact.ethnicities, e => e.guid))
        ]).pipe(take(1)).subscribe(res => {
            this.userService.setUser(res[0]);

            this.contact.clientInfo.contactGuid = this.contact.guid;
           
            this.contactService.updateClientInfo(this.contact.clientInfo).pipe(take(1)).subscribe(res => {  
                this.contact.clientInfo = res;

                this.wasSaved = true;
                
                setTimeout(() => {
                    this.wasSaved = false;
                }, 5000);

                this.isLoading = false;
            });
        });
    }
}
