import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, take } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Event } from '../_models/event';
import { ContactEvent } from '../_models/contact-event';
import { PagedSearchResults } from '../_models/paged-search-results';
import { EventSearchCriteria } from '../_models/event-search-criteria ';
import { EventImage } from '../_models/event-image';

@Injectable()
export class EventService {
    api: string = environment.api;

    constructor(private httpClient: HttpClient) { }

    getEvent(id: string): Observable<Event> {
        var url = `${this.api}events/${id}`;

        return this.httpClient.get<Event>(url);
    }

    getContactEvent(eventGuid: string, contactGuid: string): Observable<ContactEvent> {
        var url = `${this.api}events/${eventGuid}/contact/${contactGuid}`;

        return this.httpClient.get<ContactEvent>(url).pipe(take(1), map(res => {
            return res?.status != 'Canceled' ? res : null;
        }));
    }

    getChildContactEvent(eventId: string, parentId: string, childId: string): Observable<ContactEvent> {
        var url = `${this.api}events/${eventId}/contact/${parentId}/child/${childId}`;

        return this.httpClient.get<ContactEvent>(url).pipe(map(res => {
            return res?.status != 'Canceled' ? res : null;
        }));
    }

    cancelEventRegistration(eventGuid: string, contactGuid: string): Observable<ContactEvent> {
        var url = `${this.api}events/${eventGuid}/contact/${contactGuid}/cancel`;

        return this.httpClient.put<ContactEvent>(url, null);
    }

    registerForEvent(eventGuid: string, contactGuid: string): Observable<ContactEvent> {
        var url = `${this.api}events/${eventGuid}/contact/${contactGuid}/register`;

        return this.httpClient.put<ContactEvent>(url, null);
    }

    checkInToEvent(eventGuid: string, contactGuid: string): Observable<ContactEvent> {
        var url = `${this.api}events/${eventGuid}/contact/${contactGuid}/checkin`;

        return this.httpClient.put<ContactEvent>(url, null);
    }

    cancelChildEventRegistration(eventGuid: string, parentGuid: string, childId: string): Observable<ContactEvent> {
        var url = `${this.api}events/${eventGuid}/contact/${parentGuid}/child/${childId}/cancel`;

        return this.httpClient.put<ContactEvent>(url, null);
    }

    registerChildForEvent(eventGuid: string, parentGuid: string, childId: string): Observable<ContactEvent> {
        var url = `${this.api}events/${eventGuid}/contact/${parentGuid}/child/${childId}/register`;

        return this.httpClient.put<ContactEvent>(url, null);
    }

    checkInChildToEvent(eventGuid: string, parentGuid: string, childId: string): Observable<ContactEvent> {
        var url = `${this.api}events/${eventGuid}/contact/${parentGuid}/child/${childId}/checkin`;

        return this.httpClient.put<ContactEvent>(url, null);
    }

    searchEvents(criteria: EventSearchCriteria): Observable<PagedSearchResults<Event>> {
        var url = `${this.api}events/search`;

        return this.httpClient.post<PagedSearchResults<Event>>(url, criteria);
    }
}
