import { Component, OnInit } from '@angular/core';

@Component({
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.scss']
})
export class PartnersComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
