<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 340 1900 144" style="enable-background:new 0 340 1900 144;" xml:space="preserve" class="curve-bottom">
    <style type="text/css">
        .st0 {
            enable-background: new;
        }

        .st1 {
            fill-rule: evenodd;
            clip-rule: evenodd;
            fill: #49B6C2;
        }

        .st2 {
            fill-rule: evenodd;
            clip-rule: evenodd;
            fill: #304489;
        }

        .st3 {
            fill-rule: evenodd;
            clip-rule: evenodd;
        }
    </style>
    <g id="Rectangle_6_copy_5_1_" class="st0">
        <g id="Rectangle_6_copy_5">
            <g>
                <path class="st1" d="M1900,175.1c-42.5-18-230-92.1-448-92.1c-173.7,0-297.5,70-502,70c-182.9,0-417.1-54-568-54
           C184.3,99,36.9,158.4,0,174.8v303.5C61.3,447.7,429.7,279,924,392c450,114.1,906.8-41.4,976-66.6V175.1z" />
            </g>
        </g>
    </g>
    <g id="Rectangle_6_copy_2_1_" class="st0">
        <g id="Rectangle_6_copy_2">
            <g>
                <path class="st3" [style.fill]="bgColor" d="M1900,156.1C1857.5,143,1670,89,1452,89c-173.7,0-297.5,44-502,44c-182.9,0-417.1-54-568-54
           C184.3,79,36.9,138.4,0,154.8v264.8C61.3,398,429.7,280,924,393c450,114.1,906.8-41.4,976-66.6V156.1z" />
            </g>
        </g>
    </g>
</svg>