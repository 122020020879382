<div class="card">
    <table>
        <tbody>
            <tr *ngFor="let option of allOptions" [class.selected]="isSelected(option)" (click)="onClick(option)">
                <td>
                    <i *ngIf="isSelected(option)" class="far fa-square-check"></i>
                    <i *ngIf="!isSelected(option)" class="far fa-square"></i>
                </td>
                <td>
                    {{ option.name }}
                </td>
            </tr>
        </tbody>
    </table>
</div>
