<main class="p2-app" *ngIf="!!contact">
    <section class="profile">
        <div class="content">
            <h2>
                <div>Profile</div> 
            </h2>

            <div class="flex-column tighter">
                <contact-basic-info-form [(contact)]="contact" [(isFormValid)]="isProfileFormValid"></contact-basic-info-form>
                        
                <client-profile-form [(clientInfo)]="contact.clientInfo" [(isFormValid)]="isClientFormValid"></client-profile-form>

                <contact-ethnicity-form [(selectedEthnicities)]="contact.ethnicities"></contact-ethnicity-form>

                <photo-release-form [(clientInfo)]="contact.clientInfo"></photo-release-form>

                <div class="flex-row tight buttons">
                    <button class="pink medium" (click)="onSave()" [loading]="isLoading" [disabled]="!isProfileFormValid || !isClientFormValid">Save</button>
                    
                    <div class="text-with-icon" *ngIf="wasSaved">
                        <i class="fa fa-check-circle success"></i>
                        <label class="saved">Saved</label>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>
