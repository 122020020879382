import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';   
import * as _ from 'lodash';

@Directive({  
    selector: '[usPhoneValidator]',  
    providers: [  
        {  
            provide: NG_VALIDATORS,  
            useExisting: UsPhoneValidator,  
            multi: true
        }  
    ]  
})
export class UsPhoneValidator implements Validator {
    validate(c: AbstractControl): ValidationErrors | null {  
        if (!c.value || c.value.length == 0) {
            return null;
        }
        
        let regEx = new RegExp('^[- +.()0-9]+$');
        let isValid = regEx.test(c.value);
        let stripped = c.value?.replace(/[- +().]/g, '');

        isValid = isValid && stripped?.length == 10;

        return isValid ? null : { usPhoneValidator: { valid: false } };  
    }

    isInt(value) {
        return !isNaN(value) && 
               parseInt(value) == value && 
               !isNaN(parseInt(value, 10));
    }
}