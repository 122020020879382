<main class="p2-app">
    <section class="profile">
        <div class="content">
            <div class="card">
                <h4>
                    <div>Login</div> 
                </h4>
    
                <div class="flex-column">
                    <phone-lookup [(contact)]="contact" (contactChange)="onContactChanged($event)"></phone-lookup>
                </div>
            </div>
        </div>
    </section>
</main>
