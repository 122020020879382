<main>
    <h2>Staff and Board</h2>

    <ul>
        <li>
            <p>- Image here -</p>
            <h3>Betsy Downs</h3>
            <p>Executive Director & Mom Mentor</p>
            <p>Betsy has a heart to mentor teenagers and help them reach their goals.  After graduating from Purdue University in 2002 with a degree in Child Development and Family Services, she worked as a Child Life Specialist at Kosair Children's Hospital.  She went on to become a public school teacher, instructing teenagers in Relationships, Child Development, and Life Skills.  Upon giving birth to her first child, she stepped away from teaching in the schools, and worked part-time as a teen mentor with Youth for Christ.  The Lord has placed a desire on her heart to walk alongside teen moms who are often isolated and ostracized by their friends, and sometimes even by their parents and churches.</p>
        </li>
    </ul>
</main>

