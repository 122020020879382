import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RegisterComponent } from './register/register.component';
import { DonateComponent } from './donate/donate.component';
import { EventsComponent } from './events/events.component';
import { FaqsComponent } from './faqs/faqs.component';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { PartnersComponent } from './partners/partners.component';
import { StaffBoardComponent } from './staff-board/staff-board.component';
import { LifeSkillsClassesComponent } from './life-skills-classes/life-skills-classes.component';
import { MentoringComponent } from './mentoring/mentoring.component';
import { VolunteerComponent } from './volunteer/volunteer.component';
import { JobsComponent } from './jobs/jobs.component';
import { MommyAndMeComponent } from './mommy-and-me/mommy-and-me.component';
import { ChampionSponsorComponent } from './champion-sponsor/champion-sponsor.component';
import { HeartBehindP2Component } from './heart-behind-p2/heart-behind-p2.component';
import { UserProfileComponent } from './_components/user-profile/user-profile.component';
import { ChildrenComponent } from './_components/children/children.component';
import { UserEventsComponent } from './_components/user-events/user-events.component';
import { LoginComponent } from './_components/login/login.component';
import { CheckinComponent } from './checkin/checkin.component';
import { UserPreferencesComponent } from './_components/user-preferences/user-preferences.component';
import { UnsubscribeComponent } from './unsubscribe/unsubscribe.component';
import { AuthGuard } from './_services/auth-guard';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full'},
  { path: 'home', component: HomeComponent },
  { path: 'about', component: AboutComponent },
  { path: 'staff-board', component: StaffBoardComponent },
  { path: 'donate', component: DonateComponent },
  { path: 'events', component: EventsComponent },
  { path: 'events/:id', component: EventsComponent },
  { path: 'faqs', component: FaqsComponent },
  { path: 'partners', component: PartnersComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'volunteer', component: VolunteerComponent },
  { path: 'life-skills-classes', component: LifeSkillsClassesComponent },
  { path: 'mentoring', component: MentoringComponent },
  { path: 'mommy-and-me', component: MommyAndMeComponent },
  { path: 'champion-sponsorships', component: ChampionSponsorComponent },
  { path: 'heart-behind-p2', component: HeartBehindP2Component },
  { path: 'user/profile', component: UserProfileComponent, canActivate: [AuthGuard] },
  { path: 'user/children', component: ChildrenComponent, canActivate: [AuthGuard] },
  { path: 'user/events', component: UserEventsComponent, canActivate: [AuthGuard] },
  { path: 'user/preferences', component: UserPreferencesComponent, canActivate: [AuthGuard] },
  { path: 'user/:id/unsubscribe', component: UnsubscribeComponent },
  { path: 'checkin/:id', component: CheckinComponent },
  { path: 'login', component: LoginComponent }
  //{ path: 'jobs', component: JobsComponent }
];

@NgModule({
imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled' })],
exports: [RouterModule]
})
export class AppRoutingModule { }
