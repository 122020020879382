import { Component, OnInit } from '@angular/core';

@Component({
  templateUrl: './champion-sponsor.component.html',
  styleUrls: ['./champion-sponsor.component.scss']
})
export class ChampionSponsorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
